<!-- 事件概述 -->
<template>
  <div class="page">
    <!-- 事件概述 -->
    <div class="summary">
      <div class="summary-box">
        <div class="summary-left">
          <div class="label"></div>
          <div class="text">事件概述</div>
        </div>
      </div>
      <div class="content" v-html="eventSummaryDsec || '暂无概述'"></div>
    </div>
    <!-- 信息数量 --> 
    <div class="info" v-loading="loading">
      <div class="summary-box">
        <div class="summary-left">
          <div class="label"></div>
          <div class="text">信息数量 {{ infoNumForm.count }}</div>
        </div>
        <div class="summary-right">
          <div class="add">今日新增</div>
          <img
            style="height: 0.5rem; width: 0.375rem"
            src="@/assets/icons/up.png"
          />
          <div class="num">{{ infoNumForm.todayCount }}</div>
        </div>
      </div>
      <div class="distribution m-b-32">
        <div
          :class="number == index ? 'distribution-left' : 'distribution-right'"
          @click="tabSwitch(index)"
          v-for="(item, index) in distributionList"
          :key="index"
        >
          {{ item }}
        </div>
      </div>
      <div class="messagePar m-t-32 " id="echarts1"></div>
      <div class="distribution-text m-b-32">整体环节分布</div>
      <!-- 信息类型 -->
      <div
        class="info-type "
        v-for="(item, index) in infoList"
        :key="index"
        :style="index == 0 ? 'border-top:0.0625rem solid #E5E5E5' : ''"
      >
        <div class="info-type-left">{{ item.keyText }}</div>
        <div class="info-type-right">
          <span>{{ item.cnt }}</span>
          <img
            style="height: 0.5rem; width: 0.375rem"
            src="@/assets/icons/up.png"
          />
          <span>{{ item.todayCount }}</span>
        </div>
      </div>
    </div>
    <!-- 评论数量 -->
    <!-- <div class="info" v-show="topicType == 1" v-loading="commentLoading"> -->
      <!-- <div class="summary-box">
        <div class="summary-left">
          <div class="label"></div>
          <div class="text">评论数量 {{ infoNumForm.totalComments }}</div>
        </div>
        <div class="summary-right">
          <div class="add">今日新增</div>
          <img
            style="height: 0.5rem; width: 0.375rem"
            src="@/assets/icons/up.png"
          />
          <div class="num">{{ infoNumForm.todayComments }}</div>
        </div>
      </div>
      <div class="distribution">
        <div
          :class="
            commentNum == index ? 'distribution-left' : 'distribution-right'
          "
          @click="commentSwitch(index)"
          v-for="(item, index) in distributionList"
          :key="index"
        >
          {{ item }}
        </div>
      </div>
      <div class="messagePar" id="echarts2"></div>
      <div class="distribution-text">整体环节分布</div> -->
      <!-- 评论媒体类型 -->
      <!-- <div
        class="info-type"
        v-for="(item, index) in commentList"
        :key="index"
        :style="index == 0 ? 'border-top:0.0625rem solid #E5E5E5' : ''"
      >
        <div class="info-type-left">{{ item.keyText }}</div>
        <div class="info-type-right">
          <span>{{ item.totalComments }}</span>
          <img
            style="height: 0.5rem; width: 0.375rem"
            src="@/assets/icons/up.png"
          />
          <span>{{ item.todayComments }}</span>
        </div>
      </div> -->
    <!-- </div> -->
    <!-- 发布趋势 -->
    <div class="progress-box" v-loading="progressLoading">
      <div class="summary-box">
        <div class="summary-left"> 
          <div class="label"></div>
          <div class="text">发布趋势</div>
        </div>
        <div class="summary-right" @click="dakaiTime">
                    <div class="add" style="color: #F58030;">间隔1小时</div>
                    <img style="height:0.6875rem;width:0.4375rem" src="@/assets/icons/jiantou-black-right.png"/>
                </div>
      </div>
      <!-- <img
        style="height: 11.25rem; width: 100%"
        src="@/assets/icons/kong.png"
        v-if="series.length == 0"
      />
      <div
        style="width: 100%; text-align: center; color: #999999"
        v-if="series.length == 0"
      >
        暂无数据
      </div> -->
      <bitmap  v-if="series.length == 0"></bitmap>
      <div class="progress" id="columnLine"></div>
    </div>
    <!-- 评论曲线 -->
    <!-- <div v-if="topicType == 1" class="discuss-box" v-loading="curveLoading">
      <div class="summary-box">
        <div class="summary-left">
          <div class="label"></div>
          <div class="text">评论曲线</div>
        </div>
        <div class="summary-right" @click="selectItem">
          <div class="add" style="color: #3078ff">选择评论</div>
          <img
            style="height: 0.6875rem; width: 0.4375rem"
            src="@/assets/icons/jiantou-black-right.png"
          />
        </div>
      </div>
      <img
        style="height: 11.25rem; width: 100%"
        src="@/assets/icons/kong.png"
        v-if="commentLineList.length == 0"
      />
      <div
        style="width: 100%; text-align: center; color: #999999"
        v-if="commentLineList.length == 0"
      >
        暂无数据
      </div>
      <div class="discuss" v-show="commentLineList.length > 0" id="line"></div>
    </div> -->
    <!-- 关键词云 -->
    <div class="info">
      <div class="summary-box">
        <div class="summary-left">
          <div class="label"></div>
          <div class="text">关键词云</div>
        </div>
        <div class="summary-right" @click="addWordFn">
          <div class="add" style="color:#F58030"><img width="11px" src="@/assets/icons/she.png" alt="" srcset=""> 词云设置</div>
        </div>
      </div>
      <div class="wordsCloudEcharts" id="wordsCloudEcharts"></div>
      <!-- 信息类型 -->
      <div class="words-type">
        <div class="words-left">排名</div>
        <div class="words-center">关键词</div>
        <div class="words-right">词频数</div>
      </div>

      <div
        class="words-type"
        v-for="(item, index) in wordsList"
        :key="index"
        :style="index == 0 ? 'border-top:0.0625rem solid #E5E5E5' : ''"
      >
        <div class="words-left">
          <span>{{ index + 1 }} </span>
        </div>
        <div class="words-center">
          {{ item.keyword }}
        </div>
        <div class="words-right">
          <span>{{ item.count }}</span>
        </div>
      </div>
    </div>
    <!-- 首发媒体 -->
    <!-- <div class="starting" v-loading="mediaLoading">
      <div class="summary-box" style="margin-bottom: 0.9375rem">
        <div class="summary-left">
          <div class="label"></div>
          <div class="text">首发媒体</div>
        </div>
      </div>
      <img
        style="height: 11.25rem; width: 100%"
        src="@/assets/icons/kong.png"
        v-if="mediaList.length == 0"
      />
      <div
        style="
          width: 100%;
          margin-bottom: 1.25rem;
          text-align: center;
          color: #999999;
        "
        v-if="mediaList.length == 0"
      >
        暂无数据
      </div>
      <div
        @click="seeText(item)"
        class="starting-card"
        v-else
        v-for="(item, index) in mediaList"
        :key="index"
      >
        <div class="starting-head">
          <div class="starting-head-left">
            <span class="name" style="margin-left: 0rem">{{
              item.mediaLink
            }}</span>
          </div>
          <div class="starting-head-right">{{ item.releaseTime }}</div>
        </div>
        <div class="content">{{ item.title }}</div>
        <div class="starting-bottom">
          <span style="margin-right: 1.875rem">级别：{{ item.levels }}</span>
          <span style="margin-right: 1.875rem">类型：{{ item.types }}</span>
          <span style="margin-right: 1.875rem">作者：{{ item.author }}</span>
        </div>
      </div>
    </div> -->
    <!-- 词云弹出层 -->
    <van-popup v-model="Wordshow" position="bottom" class="popup">
      <div class="popup-box">
        <div class="picker">
          <div class="picker-head">
            <div>热词设置</div>
            <img
              style="height: 0.75rem; width: 0.75rem; margin-left: calc(100% - 4.75rem)"
              @click="WordshowFN"
              src="@/assets/icons/close.png"
            />
          </div>
          <div class="choose-box">
            <div class="WordBox">
              <div
                class="wordBox-item"
                @click="addtagsAll(item, inx, 1)"
                v-for="(item, inx) in tagsAll"
                :key="inx"
              >
                {{ item.name }}
              </div>
            </div>
          </div>
          <div class="picker-head">
            <div>屏蔽关键词</div>
          </div>
          <div class="WordBox-red">
            <div
              class="wordBox-item-red"
              @click="addtagsAll(item, inx, 2)"
              v-for="(item, inx) in shieldList"
              :key="inx"
            >
              {{ item.name }} <img style="margin-left:0.3125rem" width="11px" src="@/assets/icons/cha.png" alt="" srcset="">
            </div>
          </div>
        </div>
        <div  class="enter-label"> 
            <van-button class="enter-label-item a" @click="WordshowFN" type="default"
          >取消</van-button
        >
            <van-button class="enter-label-item b" @click="WordConfirm" type="default"
          >确定</van-button
        >
        </div>
       
      </div>
    </van-popup>
    <!-- 时间间隔选择器 -->
    <van-popup v-model="eventShow" position="bottom" class="popup" > 
            <div class="popup-box">
                <div class="picker">
                    <van-picker
                        title="间隔"
                        class="van-picker"
                        cancel-button-text=' '
                        confirm-button-text='×'
                        show-toolbar
                        ref="pickerEvent"
                        @confirm='eventShow = false'
                        :columns="timeList"
                        :lazy='true'
                        />
                </div>
                <van-button @click="eventConfirm" class="enter-label" type="default">确定</van-button>
            </div>
        </van-popup>
    <!-- 弹出层 -->
    <van-popup v-model="show" position="bottom" class="popup">
      <div class="popup-box">
        <div class="picker">
          <div class="picker-head">
            <div>选择评论</div>
            <img
              style="height: 0.75rem; width: 0.75rem; margin-left: calc(100% - 4.75rem)"
              @click="clsoe"
              src="@/assets/icons/close.png"
            />
          </div>
          <div class="choose-box">
            <div class="slide">
              <div
                class="slide-term"
                :style="item.show ? 'color: #3078FF;' : ''"
                v-for="(item, index) in slideList"
                :key="index"
                @click="slideChoose(item)"
              >
                <div style="margin-left: calc(50% - 2rem)">
                  {{ item.mainkeyword }}
                </div>
                <img
                  class="gou"
                  v-if="item.show"
                  src="@/assets/icons/gou.png"
                />
              </div>
            </div>
          </div>
        </div>
        <van-button @click="labelConfirm" class="enter-label" type="default"
          >确定</van-button
        >
      </div>
    </van-popup>
  </div>
</template>
<script>
import Error from "@/api/error/index";
import bitmap from "../../components/bitmap.vue";
import * as echarts from "echarts";
import Topic from "@/api/special/index.js";
import "echarts-wordcloud";  
export default {
  data() {
    return {
      tagsAll: [], // 词云关键字
      shieldList: [], // 屏蔽关键词
      time: [],
      eventSummaryDsec: "", // 事件概述
      show: false,
      topicId: this.$route.query.id,
      loading: false, //信息数量加载
      commentLoading: false, //评论数量加载
      progressLoading: false, //事件进展曲线加载
      curveLoading: false, //评论曲线加载
      mediaLoading: false, //首发媒体加载
      distributionList: ["整体分布", " 今日新增"],
      number: 0, //信息数量分布
      infoList: [], //信息类型
      commentNum: 0, //评论数量分布
      commentList: [], //跟评数量媒体类型
      mediaLogo: [
        require("@/assets/icons/weibo.png"),
        require("@/assets/icons/toutiao.png"), 
        require("@/assets/icons/xinlang.png"),
      ], //媒体logo
      infoNumForm: {}, //信息数量
      mediaList: [], //首发媒体
      commentLineList: [], //评论曲线
      eventList: [], //事件进展曲线
      slideList: [], //项目选择
      wordsList: [], // 关键词云
      Wordshow: false, // 词云弹框
      series:[],
      timeList: [//时间段
     '年度' ,
        '季度' ,
        '月份' ,
         '星期' ,
          '天' ,
         '小时' 
      ],
      eventShow:false,
      infoListData:[]
    };
  },
  components:{bitmap},
  async mounted() {
    await this.$main.mySetTimeOut(500)
    this.echarts1();
    this.echarts2();
    this.columnLine() // 发布趋势
    // this.eventLine();
    // this.comenline();
  },
  async created() {
     // 从移动端获取
     console.log(this.$route.query)
    window.localStorage.setItem('token',this.$route.query.token)
    await this.$main.mySetTimeOut(500)
        // this.getStartingMedia();
    this.eventSummary();
    this.wordCloud();
  },
  methods: {
    eventConfirm() {

    },
    // 打开
    dakaiTime() {
      this.eventShow = true
    },
    // 关闭慈云
    WordshowFN() {
        this.Wordshow = false
            this.tagsAll = [... this.shieldList,...this.tagsAll];
          this.shieldList = [];
        //   this.wordCloud();
    } ,
    // 词云选择器 确定按钮 
    async WordConfirm() {
        let data = {
        relationId: this.topicId,
      };
      if (this.shieldList.length > 0) {
        data.excludeWordCloud = this.shieldList.map((x) => x.name).join("|");
      }
        const Res = await Topic.setWordsCloudData(data) 
        if(Res.data.code) {
            this.Wordshow = false
            this.shieldList = [];
          this.tagsAll = [];
           this.wordCloud()
          
          this.$message.success("词云数据修改成功！");
        }
    },
    //  词云添加 || 屏蔽
    addtagsAll(item, inx, type) {
      // type 1:添加 2:屏蔽
      if (type == 1) {
        this.tagsAll.splice(inx, 1);
        this.shieldList.push(item);
      } else if (type == 2) {
        this.shieldList.splice(inx, 1);
        this.tagsAll.push(item);
      }
    },
    // 唤起词云
    addWordFn() {
      this.Wordshow = true; 
    },
    // 事件概述
    async eventSummary() {
      const Res = await Topic.getSpecialActionsGeneralization(this.topicId);
      this.eventSummaryDsec = Res.data.data;
    },
    // 获取关键词云
    async wordCloud() { 
      const Res = await Topic.getWordsCloudData({ relationId: this.topicId });
      this.wordsList = Res.data.data.data.slice(0, 10);
      const data2 = Res.data.data.excludeWordCloud;
      if(data2) this.shieldList = data2.split('|').map(v=>({name:v})) || []
      Res.data.data.data.forEach((item) => {
        this.tagsAll.push({
          name: item.keyword,
          value: item.count,
        });
      });

      this.wordEchart(this.tagsAll);
    },
    // 词云echarts
    wordEchart(tagsAll) {
      let myChart = echarts.init(document.getElementById("wordsCloudEcharts"));
      let option = {
        series: [
          {
            type: "wordCloud",
            //用来调整词之间的距离
            gridSize: 13,
            sizeRange: [13, 20],
            rotationRange: [0, 20],
            backgroundColor: "red",
            //随机生成字体颜色
            textStyle: {
              color: function () {
                return (
                  "rgb(" +
                  Math.round(Math.random() * 255) +
                  ", " +
                  Math.round(Math.random() * 255) +
                  ", " +
                  Math.round(Math.random() * 255) +
                  ")"
                );
              },
            },
            left: "center",
            top: "center",
            right: null,
            bottom: null,
            width: "90%",
            height: "90%",
            //数据
            data: tagsAll,
          },
        ],
      };
      myChart.setOption(option);
    },
    //首发媒体
    async getStartingMedia() {
      this.mediaLoading = true;
      let res = await Topic.startingMedia({ topicId: this.topicId });
      if (res.data.code == 200) {
        this.mediaList = res.data.data;
        this.mediaLoading = false;
      }
    },
    //首发媒体查看原文
    seeText(item) {
      window.open(item.website);
    },
    //评论曲线
    async comenline() {
      this.curveLoading = true;
      let res = await Topic.commentCurve({ topicId: this.topicId });
      if (res.data.code == 200) {
        if (res.data.data.length == 0) {
          this.curveLoading = false;
        } else {
          this.slideList = res.data.data; //评论选择数组
          this.slideList.forEach((e, index) => {
            //给评论加上选中状态
            if (index < 3) {
              //默认显示三个
              e.show = true;
            } else {
              e.show = false;
            }
          });
          let key = [];
          let time = []; //x轴时间
          let obj = {};
          let arrData = [];
          // let color = ["#009BF9", "#FFAE45","#1D50FF","#92F5A8", "#FEBC3F",];
          this.commentLineList = res.data.data.slice(0, 3);
          time = Object.keys(res.data.data[0].map);
          time = time.sort();
          this.commentLineList.forEach((e) => {
            obj = {
              name: e.mainkeyword,
              type: "line",
              stack: e.mainkeyword,
              // color: color[index],
              smooth: true,
              symbolSize: 5,
              data: [],
            };
            arrData.push(obj);
            key.push(e.mainkeyword);
            Object.keys(e.map).forEach((item) => {
              obj.data.push([item, e.map[item]]); //匹配时间
            });
            obj.data.sort();
          });
          this.line(key, time, arrData);
        }
        this.curveLoading = false;
      }
    },
    //信息数量切换整体、今日新增类型
    tabSwitch(index) {
      this.number = index;
      this.echarts1();
    },
    //评论数量切换这你、今日新增分布
    commentSwitch(index) {
      this.commentNum = index;
      this.echarts2();
    },
    //打开项目选择弹框
    selectItem() {
      this.show = true;
    },
    //选择项目
    slideChoose(item) {
      item.show = !item.show;
      let arr = this.slideList;
      this.slideList = [];
      this.slideList = arr;
    },
    //关闭弹框
    clsoe() {
      this.show = false;
    },
    //确认选择项目
    labelConfirm() {
      let arr = this.slideList.filter((e) => e.show == true);
      if (arr.length > 0) {
        this.commentLineList = arr;
        let key = [];
        let time = [];
        let obj = {};
        let arrData = [];
        // let color = ["#009BF9", "#FFAE45","#1D50FF","#92F5A8", "#FEBC3F",];
        time = Object.keys(arr[0].map);
        time = time.sort();
        arr.forEach((e) => {
          obj = {
            name: e.mainkeyword,
            type: "line",
            stack: e.mainkeyword,
            // color: color[index],
            smooth: true,
            symbolSize: 5,
            data: [],
          };
          arrData.push(obj);
          key.push(e.mainkeyword);
          Object.keys(e.map).forEach((item) => {
            obj.data.push([item, e.map[item]]); //匹配时间
          });
          obj.data.sort();
        });
        this.line(key, time, arrData);
      } else {
        this.commentLineList = [];
      }
      this.show = false;
    },
    //信息数量
    async echarts1() {
      this.loading = true;
      let res = await Topic.getSpecialActionsInformation(this.topicId);
      let arr = [];
      console.log(this.infoListData)
      if (res.data.code == 200) {
        this.infoNumForm = res.data.data;
        this.infoList = res.data.data.data;

        res.data.data.data.forEach((e) => {
          if (this.number == 0) {
            if (e.todaySumCnt > 0)
              arr.push({ value: e.cnt, name: e.keyText });
          } else {
            if (e.todayCount > 0)
              arr.push({ value: e.todayCount, name: e.keyText });
          }
        });
        console.log(arr)
        this.loading = false;
      }
      var myChart = echarts.init(document.getElementById("echarts1"));
      let option = {
        color: [
          "#92F5A8",
          "#FEBC3F",
          "#FFD88F",
          "#184CFF",
          "#6F90FF",
          "#0098FF",
          "#77C8FF",
          "#00D0A3",
        ],
        dataset: {
          source: arr,
        },
        tooltip: {
          trigger: "item",
          position: "right",
          // formatter:'{b}<br/> {c}'
          formatter: (pramas) => {
            return pramas.data.name + "<br/>" + pramas.data.value;
          },
        },
        series: [
          {
            name: "饼图",
            type: "pie",
            center: ["50%", "50%"],
            radius: 70,
            minAngle: 10,
            itemStyle: {
              borderWidth: 1,
              borderColor: "#fff",
            },
            label: {
              formatter: (pramas) => {
                return `${pramas.data.name}:${pramas.data.value}`;
              },
            },
            encode: {
              itemName: "name",
              value: "饼图",
            },
          },
        ],
      };
      myChart.setOption(option);
    }, 
    //评论数量
    async echarts2() {
      this.commentLoading = true;
      let res = await Topic.getSpecialActionsInformation(this.topicId);
      let arr = [];
      if (res.data.code == 200) {
        this.infoNumForm = res.data.data;
        this.commentList = res.data.data.commentList;

        if (res.data.data.commentList) {
          res.data.data.commentList.forEach((e) => {
            if (this.commentNum == 0) {
              arr.push({ value: e.totalComments, name: e.showname });
            } else {
              arr.push({ value: e.todayComments, name: e.showname });
            }
          });
        }
        this.commentLoading = false;
      }
      var myChart = echarts.init(document.getElementById("echarts2"));
      let option = {
        color: [
          "#92F5A8",
          "#FEBC3F",
          "#FFD88F",
          "#184CFF",
          "#6F90FF",
          "#0098FF",
          "#77C8FF",
          "#00D0A3",
        ],
        dataset: {
          source: arr,
        },
        tooltip: {
          trigger: "item",
          position: "right",
          formatter: (pramas) => {
            return pramas.data.name + "<br/>" + pramas.data.value;
          },
        },
        series: [
          {
            name: "饼图",
            type: "pie",
            center: ["50%", "50%"],
            radius: 70,
            minAngle: 10,
            itemStyle: {
              borderWidth: 1,
              borderColor: "#fff",
            },
            label: {
              formatter: (pramas) => {
                return pramas.data.name + ":" + pramas.data.value;
              },
            },
            encode: {
              itemName: "name",
              value: "饼图",
            },
          },
        ],
      };
      myChart.setOption(option);
    },
    //事件进展曲线
    // 计算各平台增量
    arraySubtraction(array) {
      let brr = [];
      for (let i in array) {
        if (i > 0) {
          brr.push(array[i] - array[i - 1]);
        }
      }
      return brr;
    },
    // 事件进展曲线
    async eventLine() {
      this.progressLoading = true;
      // 图例数组
      this.eventLineNames = [];
      // x时间数组
      this.eventLineTime = [];
      // 数据
      this.eventLineData = [];
      let obj = {};
      let res = await Topic.progressCurve({ topicId: this.topicId });
      this.progressLoading = false;
      if (res.data.data) {
        // 接口返回数据
        this.eventList = res.data.data;
      }
      for (let i = 0; i < this.eventList.length; i++) {
        // 图标上方8个图标
        if (this.eventLineNames.indexOf(this.eventList[i].showname) == -1) {
          this.eventLineNames.push(this.eventList[i].showname);
          obj = {
            name: this.eventList[i].showname,
            type: "bar",
            symbol: "none",
            stack: "正",
            itemStyle: {
              color:
                this.eventList[i].showname == "电视"
                  ? "#1D50FF"
                  : this.eventList[i].showname == "纸媒"
                  ? "#819DFF"
                  : this.eventList[i].showname == "网站"
                  ? "#1393FF"
                  : this.eventList[i].showname == "微博"
                  ? "#6EBCFF"
                  : this.eventList[i].showname == "微信公众号"
                  ? "#00B5A2"
                  : this.eventList[i].showname == "移动客户端"
                  ? "#85D9D0"
                  : this.eventList[i].showname == "互动栏目"
                  ? "#FFAE45"
                  : this.eventList[i].showname == "视频"
                  ? "#FFDCA1"
                  : "",
            },
            barWidth: 6,
            data: [],
          };
          // 八个对象 柱子
          this.eventLineData.push(obj);
        }
        // x轴
        if (this.eventLineTime.indexOf(this.eventList[i].showTime) == -1) {
          this.eventLineTime.push(this.eventList[i].showTime);
          this.eventLineTime.sort();
        }
      }
      this.eventLineData.forEach((it) => {
        this.eventLineTime.forEach((item) => {
          let off = false;
          this.eventList.forEach((i) => {
            if (item == i.showTime && it.name == i.showname) {
              off = true;
              if (it.data[it.data.length - 1]) {
                it.data.push(it.data[it.data.length - 1] + i.todayComments);
              } else {
                it.data.push(i.todayComments);
              }
            }
          });
          if (!off) {
            if (it.data[it.data.length - 1]) {
              it.data.push(it.data[it.data.length - 1]);
            } else {
              it.data.push(0);
            }
          }
        });
      });
      let deepCopyArray = JSON.parse(JSON.stringify(this.eventLineData));
      deepCopyArray.forEach((item) => {
        item.data.unshift(0);
        let arr = this.arraySubtraction(item.data);
        let wholeObj = {
          name: item.name + "(增量)",
          type: "line",
          symbol: "none",
          itemStyle: {
            color: item.itemStyle.color,
          },
          yAxisIndex: 1,
          data: [],
        };
        wholeObj.data = arr;
        this.eventLineData.push(wholeObj);
      });
      //  ezshidf
      let wholeObj = {
        name: "总体增量趋势",
        type: "line",
        symbol: "none",
        itemStyle: {
          color: "#FF509D",
        },
        yAxisIndex: 1,
        data: [],
      };
      this.eventLineTime.forEach((e) => {
        let arr = this.eventList.filter((n) => n.showTime == e); //找出相同时间的数组
        let num = 0;
        arr.forEach((i) => {
          num += i.todayComments; //累加相同时间的数据
        });
        wholeObj.data.push(num); //总体趋势data
      });
      this.eventLineData.push(wholeObj);
      this.minTime = new Date(this.eventLineTime[0]);
      this.maxTime = new Date(
        this.eventLineTime[this.eventLineTime.length - 1]
      );
      this.eventLineTimeShow = JSON.parse(JSON.stringify(this.eventLineTime));
      this.eventLineDataShow = JSON.parse(JSON.stringify(this.eventLineData));
      if (this.eventLineTime.length > 0) {
        setTimeout(() => {
          this.echarts3();
        }, 500);
      }
    },
    echarts3() {
      let myChart = echarts.init(document.getElementById("columnLine"));
      myChart.clear();
      let chartTime = JSON.parse(JSON.stringify(this.eventLineTimeShow));
      let chartData = JSON.parse(JSON.stringify(this.eventLineDataShow));
      if (this.time[0] && this.time[1] && this.time[0] <= this.time[1]) {
        let max = 0;
        let min = 0;
        let arrTime = [];
        chartTime.forEach((x) => {
          let date = new Date(
            new Date(
              new Date(this.time[1]).setDate(
                new Date(this.time[1]).getDate() + 1
              )
            ).setHours(0)
          );
          if (
            new Date(this.time[0] + " 00:00:00").getTime() <=
              new Date(x).getTime() &&
            date.getTime() >= new Date(x).getTime()
          ) {
            arrTime.push(x);
          }
          // if (new Date(this.time[0]).setHours(0) >= new Date(x)) {
          //   if (min == 0) {
          //     min = index;
          //   }
          // }
          // if (new Date(this.time[1]).setHours(23) < new Date(x)) {
          //   if (max == 0) {
          //     max = index;
          //   }
          // }
        });
        chartTime = arrTime;
        // chartTime = chartTime.slice(min, max == 0 ? chartTime.length : max);
        chartData.forEach((x) => {
          x.data = x.data.slice(min, max == 0 ? chartTime.length : max);
        });
      }
      let option = {
        grid: {
          left: "6%",
          right: "6%",
          bottom: 50,
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
        },
        legend: [
        
          {
            x: "center",
            right: "28%",
            align: "left",
            itemWidth: 18, // 设置宽度class
            itemHeight: 10,
            textStyle: {
              color: "#AAAAAA",
            },
            data: [
              {
                name: "电视(增量)",
              },
              {
                name: "纸媒(增量)",
              },
              {
                name: "网站(增量)",
              },
              {
                name: "微博(增量)",
              },
              {
                name: "微信公众号(增量)",
              },
              {
                name: "移动客户端(增量)",
              },
              {
                name: "互动栏目(增量)",
              },
              {
                name: "视频(增量)",
              },
              {
                name: "总体增量趋势",
              },
            ],
          },
          // {
          //   right: "28%",
          //   align: "left",
          //   itemWidth: 18, // 设置宽度class
          //   itemHeight: 10,
          //   textStyle: {
          //     color: "#AAAAAA",
          //   },
          //   data: ["增量趋势"],
          // },
        ],
        dataZoom: [
          {
            type: "inside",
          },
          {
            startValue: chartTime[0],
          },
        ],
        xAxis: {
          type: "category",
          data: chartTime,
          borderColor: "#D8D8D8",
          axisLine: {
            lineStyle: {
              color: "#D8D8D8",
            },
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: [
          {
            name: "总量",
            type: "value",
            axisLine: {
              lineStyle: {
                color: "#CCCCCC",
                fontSize: 12,
              },
            },
            boundaryGap: ["0%", "50%"],
            splitLine: {
              lineStyle: {
                color: "#E5E5E5",
                type: "dashed",
              },
            },
            minInterval: 1, //将刻度的最小间距设置为1
          },
          {
            name: "增量",
            axisLine: {
              lineStyle: {
                color: "#CCCCCC",
                fontSize: 12,
              },
            },
            alignTicks: true,
            type: "value",
            minInterval: 1, //将刻度的最小间距设置为1
          },
        ],
        // series: this.eventLineData   wholeObj
        series: chartData,
      };
      myChart.setOption(option);
      window.onresize = function () {
        myChart.resize();
      };
    },
    async columnLine() {
      const Res = await Topic.getReleasetrend({
        relationId: this.topicId,
      });

      this.getEcharts3(Res.data.data)

      // let myChart = echarts.init(document.getElementById("columnLine"));
      // let option = {
      //   grid: {
      //     top: "38%",
      //     left: "20px",
      //     right: "20px",
      //     bottom: 45,
      //     containLabel: true,
      //   },
      //   legend: [
      //     {
      //       icon: "circle",
      //       // left: "7%",
      //       align: "left",
      //       // top: '-1%',
      //       // width:'100%',
      //       padding: [10, 20],
      //       borderRadius: 4,
      //       itemWidth: 5, // 设置宽度class
      //       itemHeight: 5, // 设置高度im
      //       itemGap: 15, // 设置间距im
      //       textStyle: {
      //         color: "#5A6474",
      //         fontSize: 12,
      //       },
      //       backgroundColor: "#F8FAFD",
      //       data: legendList,
      //       // data: ['电视', '纸媒', '网站', '微博', '微信公众号', '移动客户端', '互动栏目', '视频', '整体趋势'],
      //     },
      //   ],
      //   // tooltip:{
      //   //     trigger:'item',
      //   //     position:'right',
      //   //     formatter:'{a}{c}'
      //   // },
      //   tooltip: {
      //     trigger: "axis",
      //   },
      //   xAxis: {
      //     type: "category",
      //     data: xtime,
      //     borderColor: "#D8D8D8",
      //     axisLine: {
      //       lineStyle: {
      //         color: "#D8D8D8",
      //       },
      //     },
      //     axisTick: {
      //       show: false,
      //     },
      //   },
      //   dataZoom: [
      //     //时间滑动
      //     {
      //       startValue: xtime[0],
      //     },
      //     {
      //       type: "inside",
      //     },
      //   ],
      //   yAxis: [
      //     {
      //       name: "总量",
      //       type: "value",
      //       axisLine: {
      //         lineStyle: {
      //           color: "#CCCCCC",
      //           fontSize: 12,
      //         },
      //       },
      //       boundaryGap: ["0%", "50%"],
      //       splitLine: {
      //         lineStyle: {
      //           color: "#E5E5E5",
      //           type: "dashed",
      //         },
      //       },
      //       minInterval: 1, //将刻度的最小间距设置为1
      //     },
      //     {
      //       name: "整体趋势",
      //       axisLine: {
      //         lineStyle: {
      //           color: "#CCCCCC",
      //           fontSize: 12,
      //         },
      //       },
      //       alignTicks: true,
      //       type: "value",
      //     },
      //   ],
      //   series: data,
      // };
      // myChart.setOption(option);
    },
    getEcharts3(res) {
      this.series= []
      let myChart = echarts.init(document.getElementById("columnLine"))
      let dataX = []
      let dataLine = []
      // let dataBar = []
      res.forEach((item) => {
        dataX.push(item.date)
        dataLine.push(item.incrementTotal)
      })
      for (let i = 0; i < 21; i++) {
        this[`data${i}`] = []
        this[`dataName${i}`] = res[0].dateDetails[i].platformTypeName
        res.forEach((v) => { 
          this[`data${i}`] = [...this[`data${i}`], v.dateDetails[i].total]
        });
        let seriesData = {
          type: 'bar',
          name: this[`dataName${i}`],
          stack: 'sum',
          barWidth: '12px',//柱子宽度
          itemStyle: {
            // normal: {
            //   borderColor: '#fff',
            //   borderWidth: 2,
            // }
          },
          data: this[`data${i}`]
        }
        this.series.push(seriesData)
        if(i==20){
          let seriseLine = {
            type: 'line',
            name: '总体增量',
            data: dataLine,
            color: '#FF509D'
          }
          this.series.push(seriseLine)
        }
      }
      let option;
      option = {
        tooltip: {
          trigger: 'axis',
          show: true,
          axisPointer: {
            type: 'shadow',
            shadowStyle: {
              color: 'rgba(0, 0, 0, 0.03)',
            },
          },
        },
        grid: {
          left: "10%",
          top: "16%",
          right: "3%",
          bottom: "12%"
        },
        legend: {
          data: ['总体增量'],
          x: '40%',
          y: '0',
        },
        xAxis: {
          // data: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],//dataX
          data: dataX,
          axisTick: {//底部小刻线
            show: false
          }
        },
        yAxis: {
          splitLine: {//虚线设置
            show: true,
            lineStyle: {
              type: 'dashed'
            }
          }
        },
        series:this.series,
      };
      option && myChart.setOption(option);
      window.addEventListener("resize", () => {
        myChart.resize();
      });
    },
    //评论曲线
    line(key, time, arrData) {
      echarts.init(document.getElementById("line")).dispose();
      var chartDom = document.getElementById("line");
      var myChart = echarts.init(chartDom);
      var option;
      option = {
        title: {},
        tooltip: {
          trigger: "axis",
        },
        // dataZoom: [  //时间滑动
        //     {
        //         startValue: "2014-06-01"
        //     },
        //     {
        //         type: "inside"
        //     }
        // ],
        legend: {
          data: key,
          textStyle: {
            color: "#5A6474",
            fontSize: 12,
          },
          itemWidth: 15, // 设置宽度class
          itemHeight: 8, // 设置高度im
        },
        grid: {
          left: "10%",
          top: "15%",
          right: "10%",
          bottom: "45px",
          containLabel: true,
        },
        // toolbox: {
        //     feature: {
        //         saveAsImage: {}
        //     }
        // },
        // xAxis: {
        //     type: 'category',
        //     borderColor: "#D8D8D8",
        //     axisLine: {
        //         lineStyle: {
        //             color: "#D8D8D8",
        //         },
        //     },
        //     axisTick: {
        //         show: false,
        //     },
        //     // boundaryGap: false,
        //     data: ['02:00', '03:00', '04:00', '06:00', '07:00', '08:00']
        // },
        dataZoom: [
          //时间滑动
          {
            startValue: time[0],
          },
          {
            type: "inside",
          },
        ],
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            data: time,
            // axisLabel : {//坐标轴刻度标签的相关设置。
            //     interval:0,
            //     rotate:"-15"
            // }
          },
        ],
        yAxis: [
          {
            type: "value",
            boundaryGap: ["0%", "20%"],
            minInterval: 1, //将刻度的最小间距设置为1
          },
        ],
        series: arrData,
      };

      option && myChart.setOption(option);
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%; 
  background: #f3f4f7;
  .summary {
    width: 100%;
    height: auto;
    padding: 15px 0px 16px 0px;
    background: #ffffff;
    .content {
      display: flex;
      flex-wrap: wrap;
      padding: 0px 20px;
      width: calc(100% - 40px);
      height: auto;
      font-size: 14px;
      margin-top: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 22px;
    }
  }
  .summary-box {
    display: flex;
    width: 100%;
    height: 21px;
    .summary-left {
      display: flex;
      align-items: center;
      height: 21px;
      width: 50%;
      .label {
        width: 4px;
        height: 14px;
        margin-right: 16px;
        background: linear-gradient(180deg, #1eb9ff 0%, #027ffe 100%);
      }
      .text {
        font-size: 15px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
      }
    }
    .summary-right {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 21px;
      padding-right: 20px;
      width: calc(50% - 20px);
      .add {
        height: 17px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 17px;
        margin-right: 8px;
      }
      .num {
        height: 17px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #02bc7c;
        line-height: 17px;
        margin-left: 3px;
      }
    }
  }
  .info {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #ffffff;
    margin-top: 12px;
    padding: 15px 0px 10px 0px;
    height: auto;
    width: 100%;
    .wordsCloudEcharts {
      width: 100%;
      height: 330px;
    }
    .distribution {
      display: flex;
      width: 192px;
      height: 27px;
      background: #ffffff;
      border-radius: 4px;
      margin-top: 20px;
      border: 1px solid #333333;
      .distribution-left {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 96px;
        height: 27px;
        background: #333333;
        font-size: 12px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
      }
      .distribution-right {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 96px;
        height: 27px;
        background: #ffffff;
        border-radius: 4px;
        font-size: 12px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 400;
        color: #333333;
        border-bottom: 1px solid;
      }
    }
    .messagePar {
      width: 100%;
      height: 330px;
    }
    .distribution-text {
      min-width: 84px;
      height: 17px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      line-height: 17px;
      margin-bottom: 20px;
    }
    .info-type {
      display: flex;
      height: 44px;
      width: calc(100% - 40px);
      margin: 0px 20px;
      border-bottom: 1px solid #e5e5e5;
      .info-type-left {
        display: flex;
        align-items: center;
        height: 44px;
        width: 60%;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 20px;
      }
      .info-type-right {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 44px;
        width: 40%;
      }
      .info-type-right span:nth-child(1) {
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 20px;
        margin-right: 10px;
      }
      .info-type-right span:nth-child(3) {
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #02bc7c;
        line-height: 20px;
        margin-left: 5px;
      }
    }
    .words-type {
      display: flex;
      width: calc(100% - 40px);
      border-top: 1px solid #e5e5e5;
      height: 44px;
      align-items: center;
      justify-content: space-around;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 20px;
      div {
        flex: 1;
      }
      .words-left {
        text-align: start;
        span {
          margin-left: 10px;
        }
      }
      .words-center {
        text-align: center;
      }
      .words-right {
        text-align: right;
        span {
          margin-right: 10px;
        }
      }
    }
  }
  .progress-box {
    width: 100%;
    height: auto;
    padding: 15px 0px 10px 0px;
    margin-top: 12px;
    background: #ffffff;
    .progress {
      height: 242px;
      width: 100%;
      margin-top: 12px;
    }
  }
  .discuss-box {
    width: 100%;
    height: 233px;
    padding: 15px 0px 10px 0px;
    margin-top: 12px;
    background: #ffffff;
    .discuss {
      height: 202px;
      width: 100vw;
      margin-top: 10px;
    }
  }
  .starting {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    padding-top: 15px;
    margin-top: 12px;
    background: #ffffff;
    .starting-card {
      display: flex;
      flex-direction: column;
      width: calc(100% - 40px);
      height: 73px;
      padding: 15px 0px;
      margin: 0px 20px;
      border-top: 1px solid #e5e5e5;
      .starting-head {
        display: flex;
        height: 20px;
        width: 100%;
        .starting-head-left {
          display: flex;
          align-items: center;
          height: 20px;
          width: 50%;
          .name {
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
            margin-left: 5px;
          }
        }
        .starting-head-right {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          height: 20px;
          width: 50%;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
        }
      }
      .content {
        width: 100%;
        // height: 17px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 17px;
        margin-top: 10px;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        line-clamp: 1;
        -webkit-box-orient: vertical;
      }
      .starting-bottom {
        display: flex;
        width: 100%;
        height: 17px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 17px;
        margin-top: 10px;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }
  }
  // 弹出层
  .popup {
    width: 100%;
    height: 60vh;
    background: #ffffff;
    border-radius: 10px 10px 0px 0px;
    .popup-box {
      // display: flex;
      // flex-direction: column;
      position: relative;
      height: 100%;
      width: 100%;
      .picker {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: calc(100% - 100px);
        padding: 0px 15px;
        width: calc(100% - 30px);
        margin-bottom: 30px;
        .picker-head {
          display: flex;
          align-items: center;
          height: 23px;
          width: 100%;
          margin-top: 15px;
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: bold;
          color: #333333;
        }
        .choose-box {
          height: 177px;
          width: 100%;
          height: 50%;
          overflow: scroll;
          .WordBox {
            display: flex;
            flex-wrap: wrap;
            .wordBox-item {
              padding: 10px 15px;
              margin-right: 10px;
              margin-top: 15px;
              background: #f3f4f7;
              border-radius: 4px;
            }
          }
          .slide {
            display: flex;
            flex-direction: column;
            height: auto;
            width: 100%;
            .slide-term {
              display: flex;
              align-items: center;
              height: 59px;
              width: 100%;
              font-size: 16px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: bold;
              color: #333333;
              .gou {
                height: 12px;
                width: 17px;
                margin-left: 15px;
              }
            }
          }
        }
        .WordBox-red {
          width: 100%;
          min-height: 70px;
          max-height: 100px;
          overflow: scroll;
          display: flex;
          flex-wrap: wrap;
          justify-content: start;
          .wordBox-item-red {
            padding: 10px 15px;
            // height: 20px;
            margin-right: 10px;
            margin-top: 15px;
            color: #f58030;
            background: #fff1e8;
            border-radius: 4px;
          }
        }
      }
      .enter-label {
        position: absolute;
        bottom: 10px;
        width: calc(100% - 40px);
        padding-left:20px ;
        height: 44px;
        // background: #3078ff;
        display: flex;
        justify-content: space-evenly;
        .enter-label-item{
            flex: 1;
            border-radius: 2px;
        // margin: 0px 20px;
        border: 0px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        // color: #ffffff;
        }
        .a{
            background: #EBECEF;
            color:black;
        }
        .b{
            background: #F58030;
            color: #ffffff;
            margin-left:20px ;
        }
      }
    }
  }
}
</style> 